/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Después de un tiempo ", React.createElement(_components.a, {
    href: "/2024/06/21/en-busca-de-enlaces-bidireccionales/"
  }, "valorando cómo recuperar los enlaces bidireccionales"), " al final me he decantado por Webmentions, primero porque ya hace años que me atrae la idea e incluso ", React.createElement(_components.a, {
    href: "/2015/01/20/fiasco-del-plugin-webmentions/"
  }, "lo he intentado anteriormente"), " y segundo porque habilita muchas más funcionalidades."), "\n", React.createElement(_components.p, null, "Así que después de pelear con ciertos servicios de la IndieWeb, de donde parte esta idea, he conseguido habilitar el servicio de Webmentions en este blog estático. Esto me permite lo siguiente:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Tener conversaciones realmente distribuidas en la blogosfera, ahora cualquiera con otro blog puede contestar a mis posts mencionándolos con un Webmention."), "\n", React.createElement(_components.li, null, "Conexión con el fediverso. Esta funcionalidad viene dada por el servicio ", React.createElement(_components.a, {
    href: "https://brid.gy"
  }, "Bridgy"), " y permite que cuando comparta un post con mi cuenta de Mastodon las reacciones se conviertan en Webmentions y aparecen automáticamente en mi blog. Así que con este servicio consigo federar de alguna forma mi blog con el fediverso."), "\n", React.createElement(_components.li, null, "Tener enlaces bidireccionales, puesto que generaré Webmentions a publicaciones anteriores."), "\n"), "\n", React.createElement(_components.p, null, "Ahora estoy en fase de testeo así que, ya que estás aquí, te pido que contestes a este post con un comentario, un like o un repost en mi publicación en Mastodon o si tienes un blog con Webmentions me respondas y de paso le damos un poco de publicidad a este gran estándar, me haría mucha ilusión."), "\n", React.createElement(_components.p, null, "Y esto es todo, hablaré más adelante sobre mi experiencia implementando esto porque no ha sido sencillo para alguien como yo con muy poca experiencia en Javascript, pero lo he conseguido y creo que merece la pena."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
